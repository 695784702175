import "inter-ui";
import "./assets/app.css";
import { createApp } from "vue";
import App from "./App.vue";
import { Amplify, Auth } from "aws-amplify";
import aws_exports from "./aws-exports";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "axios";
import config from "@/config/app";
import features from "@/config/features";

Amplify.configure({
  ...aws_exports,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});
const app = createApp(App);
app.config.globalProperties.$http = axios;
app.component(VueQrcode.name, VueQrcode);

let ssoLoginMatch = window.location.pathname.match(
  /^\/sso\/[0-9A-Za-z-]+\/login$/gm
);
if (features.sso && ssoLoginMatch) {
  const ssoLoginParts = ssoLoginMatch[0].split("/");
  axios
    .get(`${config.vgw_platform.url}/api/auth/sso/idp/${ssoLoginParts[2]}`)
    .then(async (r) => {
      if (Array.isArray(r.data) && r.data.length && r.data[0].redirect) {
        try {
          await Auth.signOut();
        } catch (error) {
          console.log("error signing out: ", error);
        }

        window.location.href = r.data[0].redirect;
      }
    });
} else {
  app.mount("#app");
}
