<template>
  <div>
    <h1 class="pb-3">Single Sign-On</h1>
    <p class="h-info pb-5">Enter your company email address into the field below and then click "Continue".</p>

    <p v-if="errorMessage" class="error mb-4">{{ errorMessage }}</p>

    <label for="email" class="block text-sm font-normal text-gray-600 mb-2">Email address</label>
    <input @keyup.enter="signIn" id="email" type="email" v-model="email" autofocus class="block mb-4">

    <button type="button" @click="signIn" class="primary mb-12">
      <div class="flex justify-center items-center">
        <spinner v-if="signingIn"></spinner>
        <template v-if="!signingIn">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
          <span>Continue</span>
        </template>
      </div>
    </button>

    <button @click="$emit('cancel')" type="button" class="secondary mb-12">
      <div class="flex justify-center items-center">
        <svg class="w-4 h-4 mr-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
        <span>Back to login</span>
      </div>
    </button>
  </div>
</template>

<script>
import Spinner from "./Spinner";
import config from "@/config/app";

export default {
  name: 'sso',
  components: {Spinner},
  data() {
    return {
      email: '',
      signingIn: false,
      errorMessage: '',
    }
  },
  methods: {
    signIn: async function() {
      this.signingIn = true
      await this.$http.get(`${config.vgw_platform.url}/api/auth/sso/idp/search?email=` + this.email)
          .then(r => {
            if (Array.isArray(r.data) && r.data.length && r.data[0].redirect) {
              window.location.href = r.data[0].redirect
              return
            }

            this.errorMessage = 'The email address you\'ve provided isn\'t associated with an SSO account. Please double check the email address is correct and then try again. If you believe the email address is correct please contact your account manager for assistance.'
          })
          .catch(() => {
            this.errorMessage = 'An error occurred during the request. Please try again.'
          })
          .finally(() => {
            this.signingIn = false
          })
    }
  }
}
</script>