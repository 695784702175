export default [
    {
        name: 'Microsoft Authenticator',
        url: 'https://www.microsoft.com/en-us/security/mobile-authenticator-app',
    },
    {
        name: 'Google Authenticator',
        url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US',
    },
    {
        name: 'Authy',
        url: 'https://authy.com/download',
    },
    {
        name: 'Duo Mobile',
        url: 'https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app',
    },
    {
        name: 'LastPass Authenticator',
        url: 'https://lastpass.com/auth/',
    }
]