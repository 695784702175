<template>
  <div>
    <template v-if="state === 'select' && code">
      <h1 class="pb-3">Protect Your Account</h1>
      <p class="h-info pb-5">Two-factor authentication is an extra layer of security designed to ensure that you're the only person who can access your account, even if someone else knows your password.</p>
      <p class="h-info pb-5">Whenever you login they'll be a second step asking you to provide a time based verification code. The code lives on an authenticator app installed on your phone, so even if someone knows your password, they'll be unable to access your account.</p>
      <button type="button" @click="state = 'setup'" class="primary mb-3">
        <div class="flex justify-center items-center">
            <svg class="w-4 h-4 mr-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
            <span>Setup two-factor authentication now</span>
        </div>
      </button>

      <button v-if="canSkip" @click="$emit('skip', user)" type="button" class="secondary mb-12">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          <span>Continue without securing my account</span>
        </div>
      </button>

      <button v-else @click="$emit('cancel')" type="button" class="secondary mb-12">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="840.000000pt" height="826.000000pt" viewBox="0 0 840.000000 826.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,826.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none"><path d="M2130 7504 c-1463 -668 -1565 -714 -1581 -714 -8 0 -39 -7 -67 -15 -110 -33 -214 -167 -259 -335 -17 -61 -18 -199 -18 -2325 0 -2126 1 -2264 18 -2325 45 -168 149 -302 259 -335 28 -8 59 -15 68 -15 10 0 53 -16 96 -37 43 -20 572 -261 1174 -535 1211 -551 1112 -514 1213 -460 94 51 175 168 214 312 17 62 18 243 18 3395 0 3152 -1 3333 -18 3395 -39 143 -119 261 -214 312 -102 55 -42 76 -903 -318z m272 -2775 c249 -52 406 -301 343 -544 -88 -336 -501 -447 -747 -202 -112 112 -156 286 -109 436 26 85 61 140 126 199 112 102 246 141 387 111z"/><path d="M3920 6910 l0 -300 810 0 810 0 0 -545 0 -545 261 0 260 0 -3 598 c-3 595 -4 597 -27 667 -68 203 -184 332 -357 397 l-59 23 -847 3 -848 3 0 -301z"/><path d="M6446 5973 c-3 -3 -6 -188 -6 -410 l0 -403 -1005 0 -1005 0 2 -932 3 -933 1003 -3 1002 -2 0 -368 c0 -203 3 -371 6 -375 5 -5 1688 1643 1732 1695 7 9 9 22 6 29 -4 8 -179 183 -389 389 -210 206 -598 588 -862 847 -264 260 -483 470 -487 466z"/><path d="M5547 3023 c-4 -3 -7 -321 -7 -705 l0 -698 -810 0 -810 0 0 -300 0 -300 814 0 c461 0 835 4 862 9 169 32 304 137 389 302 72 140 69 100 72 942 l4 757 -254 0 c-139 0 -257 -3 -260 -7z"/></g></svg>
          <span>Logout</span>
        </div>
      </button>
    </template>

    <template v-if="state === 'success'">
      <h1 class="pb-3">Setup Complete</h1>
      <p class="h-info mb-12">Your account has been successfully setup to use two-factor authentication. Next time you login you'll be asked to input the verification code on your authenticator app. Please click the button below to continue.</p>
      <button type="button" @click="$emit('success')" class="primary mb-3">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>

          <span>Continue</span>
        </div>
      </button>
    </template>

    <template v-if="state === 'setup' && code">
      <h1 class="pb-3">Setup Two-factor Authentication</h1>
      <p class="h-info pb-5">Please complete the following steps to secure your account with two-factor authentication.</p>

      <ul class="text-sm text-gray-500">
        <li class="flex border-b border-dashed pb-8 mb-8">
          <div class="mr-3">
            <span class="circle">1</span>
          </div>
          <div class="pt-0.5">
            <h2 class="mb-3">Install Authenticator App</h2>
            <span class="text-sm">Download and install one of authenticator apps listed below on your mobile device. If you're unsure which app to choose, pick either "Google Authenticator" or "Microsoft Authenticator".</span>
            <ul class="text-sm block pl-5 pt-3 list-disc list-outside mb-6">
              <li v-for="(app, i) in apps" :key="i">
                <a :href="app.url" rel="nofollow" target="_blank" class="flex items-center hover:underline">
                  <span class="mr-1">{{ app.name }}</span>
                  <svg class="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" /><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" /></svg>
                </a>
              </li>
            </ul>
            <div class="flex items-start text-gray-500 text-xs bg-gray-100 rounded-lg px-4 py-3">
              <div><svg class="block w-4 h-4 mt-0.5 mr-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></div>
              <p class="block">Want to use an authenticator app not listed above? We support any authenticator app utilising TOTP (Time-based One-Time Password).</p>
            </div>
          </div>
        </li>

        <li class="flex border-b border-dashed pb-8 mb-8">
          <div class="mr-3">
            <span class="circle">2</span>
          </div>
          <div class="pt-0.5">
            <h2 class="mb-3">Scan The QR Code</h2>

            <p class="text-sm mb-4">Open the authenticator app installed in the previous step and click "Add account" or the plus symbol (depending on which app you're using). Follow the on screen instructions and then select the "Scan QR Code" option. Point your phones camera to the below image and the authenticator app will automatically detect it:</p>
            <vue-qrcode class="mb-4" :value="code" :options="{ width: 150 }"></vue-qrcode>
            <h4 @click="showManualSetupInstructions = !showManualSetupInstructions" class="font-semibold text-secondary cursor-pointer">Can't scan the image?</h4>
            <div v-if="showManualSetupInstructions" class="rounded p-5 bg-gray-100 mt-2">
              <p class="text-sm mb-2">If you're having trouble scanning the QR code, use the manual account setup option on your authenticator app. When asked you'll need to input the following code:</p>
              <p class="font-semibold text-xs break-all">{{ rawCode }}</p>
            </div>
          </div>
        </li>

        <li class="flex border-b border-dashed pb-8 mb-8">
          <div class="mr-3">
            <span class="circle">3</span>
          </div>
          <div class="pt-0.5 flex-grow">
            <h2 class="mb-3">Enter The Verification Code</h2>

            <p class="text-sm mb-4">On your authenticator app you should see the VettingGateway account you've setup in the previous step. Enter the verification code shown beside the account name in the field below and then click the "Verify" button.</p>
            <label for="code" class="block text-sm font-normal text-gray-600 mb-2">Verification code</label>
            <input @keyup.enter="verify" id="code" type="text" minlength="6" v-model="answer" class="block mb-4">
          </div>
        </li>
      </ul>

      <p v-if="errorMessage" class="error mb-4">{{ errorMessage }}</p>

      <button type="button" @click="verify" class="primary mb-3">
        <div class="flex justify-center items-center">
          <spinner v-if="verifying"></spinner>
          <template v-if="!verifying">
            <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
            <span>Verify</span>
          </template>
        </div>
      </button>

      <button @click="$emit('cancel')" type="button" class="secondary mb-12">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="840.000000pt" height="826.000000pt" viewBox="0 0 840.000000 826.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,826.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none"><path d="M2130 7504 c-1463 -668 -1565 -714 -1581 -714 -8 0 -39 -7 -67 -15 -110 -33 -214 -167 -259 -335 -17 -61 -18 -199 -18 -2325 0 -2126 1 -2264 18 -2325 45 -168 149 -302 259 -335 28 -8 59 -15 68 -15 10 0 53 -16 96 -37 43 -20 572 -261 1174 -535 1211 -551 1112 -514 1213 -460 94 51 175 168 214 312 17 62 18 243 18 3395 0 3152 -1 3333 -18 3395 -39 143 -119 261 -214 312 -102 55 -42 76 -903 -318z m272 -2775 c249 -52 406 -301 343 -544 -88 -336 -501 -447 -747 -202 -112 112 -156 286 -109 436 26 85 61 140 126 199 112 102 246 141 387 111z"/><path d="M3920 6910 l0 -300 810 0 810 0 0 -545 0 -545 261 0 260 0 -3 598 c-3 595 -4 597 -27 667 -68 203 -184 332 -357 397 l-59 23 -847 3 -848 3 0 -301z"/><path d="M6446 5973 c-3 -3 -6 -188 -6 -410 l0 -403 -1005 0 -1005 0 2 -932 3 -933 1003 -3 1002 -2 0 -368 c0 -203 3 -371 6 -375 5 -5 1688 1643 1732 1695 7 9 9 22 6 29 -4 8 -179 183 -389 389 -210 206 -598 588 -862 847 -264 260 -483 470 -487 466z"/><path d="M5547 3023 c-4 -3 -7 -321 -7 -705 l0 -698 -810 0 -810 0 0 -300 0 -300 814 0 c461 0 835 4 862 9 169 32 304 137 389 302 72 140 69 100 72 942 l4 757 -254 0 c-139 0 -257 -3 -260 -7z"/></g></svg>
          <span>Logout</span>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import authenticatorApps from '@/config/authenticator-apps'
import Spinner from "./Spinner";

export default {
  name: 'MfaSetup',
  components: {Spinner},
  props: ['user'],
  data() {
    return {
      state: 'select',
      code: '',
      rawCode: '',
      answer: '',
      verifying: false,
      errorMessage: '',
      apps: authenticatorApps,
      showManualSetupInstructions: false,
    }
  },
  computed: {
      canSkip() {
        return this.user.attributes["custom:enforce_mfa"] !== "1"
      }
  },
  mounted() {
    this.session()
    Auth.setupTOTP(this.user).then((code) => {
      this.code = "otpauth://totp/"+this.user.attributes.email+":"+ this.user.username + "?secret=" + code + "&issuer=VettingGateway"
      this.rawCode = code
    })
  },
  methods: {
    session: async function() {
      console.log(await Auth.currentSession());
    },
    verify: function () {
      this.verifying = true
      const challengeAnswer = this.answer

      if (challengeAnswer.length < 6) {
        this.verifying = false

        this.errorMessage = 'The code must be at least 6 characters'

        return
      }

      Auth.verifyTotpToken(this.user, challengeAnswer).then(data => {
        Auth.setPreferredMFA(this.user, 'TOTP')
        window.localStorage.setItem(`sm-${this.user.username}`, '1');
        this.state = 'success'
      }).catch( e => {
        console.log(e)
        this.errorMessage = 'The verification code is incorrect.'
      }).finally(() => {
        this.verifying = false
      })
    }
  }
}
</script>
