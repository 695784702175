<template>
  <div>
    <h1 class="pb-3">Sign in</h1>
    <p class="h-info pb-5">Please enter your sign in details to continue.</p>
    <p v-if="errorMessage" class="error mb-4">{{ errorMessage }}</p>

    <label for="email" class="block text-sm font-normal text-gray-600 mb-2">Email address</label>
    <input @keyup.enter="signIn" id="email" type="email" v-model="username" autofocus class="block mb-4">

    <div v-if="state === 'password'">
      <label for="password" class="block text-sm font-normal text-gray-600 mb-2">Password</label>
      <input @keyup.enter="signIn" id="password" type="password" v-model="password" class="block mb-2">

      <div class="flex justify-between align-center text-xs mb-10">
        <span class="text-gray-400">Passwords are case sensitive</span>
        <span @click="$emit('forgotPassword')" class="cursor-pointer text-secondary font-semibold">Forgotten Password?</span>
      </div>
    </div>

    <button type="button" @click="signIn" class="primary mb-6">
      <div class="flex justify-center items-center">
        <spinner v-if="signingIn"></spinner>
        <template v-if="!signingIn">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
          <span v-if="state === 'email'">Continue</span>
          <span v-if="state === 'password'">Sign in</span>
        </template>
      </div>
    </button>

    <div class="text-xs mb-12 text-gray-400">
      <p class="mb-2">Unauthorised access is prohibited and constitutes an offence under the Computer Misuse Act 1990. It may result in criminal prosecution punishable by imprisonment or fine, or both, and a claim for civil damages.</p>
      <p>Authorised users are reminded to keep their username and password confidential and to contact support immediately they become aware of any unauthorised user obtaining their username and password.</p>
    </div>

    <template v-if="ssoEnabled">
      <h2 class="pb-3">Single Sign-On</h2>
      <p class="h-info pb-5">Looking to sign in with your organisations SSO provider such as Microsoft? Click the button below.</p>

      <button @click="$emit('sso')" type="button" class="mb-12 secondary">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
          <span>Sign in with your corporate identity</span>
        </div>
      </button>
    </template>


    <h2 class="pb-3">Don't have an account?</h2>
    <p class="h-info pb-5">Creating an account takes seconds and you only need one to manage checks from different organisations.</p>

    <a @click="$emit('create-account')" class="cursor-pointer secondary mb-8">
      <div class="flex justify-center items-center">
        <svg class="w-4 h-4 mr-0.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" /><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" /></svg>
        <span>Create an account</span>
      </div>
    </a>

  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import Spinner from "./Spinner";
import features from "@/config/features";

export default {
  name: 'Login',
  components: {Spinner},
  data() {
    return {
      username: '',
      password: '',
      signingIn: false,
      errorMessage: '',
      state: 'password',
    }
  },
  computed: {
    ssoEnabled() {
      return features.sso
    }
  },
  methods: {
    signIn: async function() {
      if (!this.username.length || !this.password.length) {
        this.errorMessage = 'Please enter both your username and password'

        return
      }

      this.signingIn = true
      try {
        this.errorMessage = ''
        const user = await Auth.signIn(this.username, this.password);
        user.email = this.username
        this.$emit('success', user)
      } catch (error) {
        console.log(error)
        if (error.code === 'PasswordResetRequiredException') {
          this.$emit('passwordReset', this.username)
        } else {
          this.errorMessage = typeof error.message === 'string' ? error.message : 'Login failed';
        }
      } finally {
        this.signingIn = false
      }
    }
  }
}
</script>
