import { Auth } from "aws-amplify";
import config from "@/config/app";

function redirect(redirectPath, source) {
  window.location.href =
    typeof redirectPath === "undefined"
      ? `/${source}`
      : `${config.vgw_platform.url}/${redirectPath}`;
}

export const logout = async function logout(redirectPath) {
  const queryParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(queryParams.entries());

  const resetUsername = params["rm-reset"];
  if (typeof resetUsername === "string") {
    window.localStorage.removeItem(`rm-${resetUsername}`);
    window.localStorage.removeItem(`rmt-${resetUsername}`);
  }

  const sourceParam = params["source"];
  let source = "";
  if (typeof sourceParam === "string" && sourceParam.length) {
    source = `?source=${sourceParam}`;
  }

  try {
    Auth.currentAuthenticatedUser().then(async (user) => {
      window.localStorage.removeItem(`sm-${user.username}`);
      await Auth.signOut();
    });
  } catch (e) {
    console.log(e);
  } finally {
    redirect(redirectPath, source);
  }
};
