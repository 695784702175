<template>
  <div>
    <h1 class="pb-3">Password Reset</h1>
    <p class="h-info pb-5">To restore access to your account you'll need to request a password reset. Please enter your email address in the field below.</p>
    <label for="email" class="block text-sm font-normal text-gray-600 mb-2">Email address</label>
    <input @keyup.enter="send" id="email" type="email" v-model="email" autofocus class="block mb-12">

    <p v-if="errorMessage" class="error mb-4">{{ errorMessage }}</p>

    <button type="button" @click="send" class="primary mb-3">
      <div class="flex justify-center items-center">
        <spinner v-if="sending"></spinner>
        <template v-if="!sending">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
          <span>Continue</span>
        </template>
      </div>
    </button>

    <button @click="$emit('cancel')" type="button" class="secondary mb-12">
      <div class="flex justify-center items-center">
        <svg class="w-4 h-4 mr-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
        <span>Cancel</span>
      </div>
    </button>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import Spinner from "./Spinner";
import config from "@/config/app";

export default {
  name: "AccountRecovery",
  components: {Spinner},
  data() {
    return {
      email: '',
      sending: false,
      errorMessage: '',
    }
  },
  methods: {
    send() {
      this.sending = true
      Auth.forgotPassword(this.email)
          .then(() => {
            try {
              this.$http.post(`${config.vgw_platform.url}/api/password/reset`, {email: this.email})
            } catch (e) {
              console.log(e)
            }
            this.errorMessage = ''
            this.$emit('sent', this.email)
          }).catch((err) => {
            console.log(err)
            this.errorMessage = err.message || 'Failed to process request - please try again in a few moments'
          }).finally(() => {
            this.sending = false
          })
    }
  }
}
</script>

<style scoped>

</style>