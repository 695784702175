<template>
  <div>
    <div v-if="state === 'loading'"></div>
    <div v-if="state !== 'loading'" class="flex md:justify-center">
      <div id="banner" class="sm:hidden xl:block lg:sticky flex-grow bg-center bg-cover bg-no-repeat"></div>
      <div id="auth" class="sm:w-full md:w-7/12 lg:w-6/12 xl:w-5/12 max-w-2xl min-h-screen">
        <div class="px-10 pt-16 pb-10">
          <img id="logo" src="./assets/logo.webp" class="pb-12">

          <login v-if="state === 'login'" @success="handleLoginSuccess($event)"
                 @passwordReset="state = 'passwordReset'; username = $event"
                 @forgotPassword="state = 'accountRecovery'"
                 @createAccount="redirectToAccountCreation"
                 @sso="state = 'sso'"></login>

          <sso v-if="state === 'sso'" @cancel="state = 'login'"></sso>

          <mfa-setup v-if="state === 'mfaSetup'" :user="user" @success="handleAuthenticated" @skip="handleAuthenticated"
                     @cancel="logout"></mfa-setup>
          <mfa-challenge v-if="state === 'mfaChallenge'" :user="user" @success="handleAuthenticated($event)"
                         @cancel="logout"></mfa-challenge>
          <password-reset v-if="state === 'passwordReset'" :user="user" :username="username" @success="logout"
                          @cancel="logout"></password-reset>
          <account-recovery v-if="state === 'accountRecovery'" @cancel="logout"
                            @sent="state = 'passwordReset'; username = $event"></account-recovery>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./components/Login";
import MfaSetup from "./components/MfaSetup";
import MfaChallenge from "./components/MfaChallenge";
import PasswordReset from "./components/PasswordReset";
import AccountRecovery from "./components/AccountRecovery";
import {Auth} from "aws-amplify";
import {logout} from "./functions";
import config from "@/config/app"
import Sso from "@/components/SSO";

export default {
  name: 'App',
  components: {Sso, AccountRecovery, PasswordReset, Login, MfaSetup, MfaChallenge},
  data() {
    return {
      user: false,
      username: '',
      state: 'loading',
      source: null,
    }
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(queryParams.entries());
    const sourceParam = params['source'];
    if (typeof sourceParam === 'string' && sourceParam.length) {
      this.source = sourceParam
    }

    if (window.location.pathname.indexOf('/logout') === 0) {
      this.logout()
      return
    }

    Auth.currentAuthenticatedUser()
        .then(user => {
          console.log("curr", user)
          this.user = user

          if (user.attributes["custom:enforce_mfa"] === "1") {
            if (window.localStorage.getItem(`sm-${user.username}`) === '1') {
              return this.handleAuthenticated();
            }

            let rm = window.localStorage.getItem(`rm-${user.username}`);
            if (typeof rm === 'string' && new Date(rm).valueOf() > new Date().valueOf()) {
              return this.handleAuthenticated();
            }

            if (user.Session === null) {
              this.logout()
              return
            }

            this.state = this.user.preferredMFA === 'SOFTWARE_TOKEN_MFA' ? 'mfaChallenge' : 'mfaSetup'

            return
          }

          this.handleAuthenticated();
        })
        .catch(() => {
          this.state = 'login'
        })
  },
  methods: {
    handleLoginSuccess(user) {
      this.user = user

      if (this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // todo: if i reset mfa, set it back up again but dont click remeber me - it will still remember me?
        let rm = window.localStorage.getItem(`rm-${user.username}`);
        let rmt = window.localStorage.getItem(`rmt-${user.username}`);

        if (typeof rm === 'string' && typeof rmt === 'string' && new Date(rm).valueOf() > new Date().valueOf()) {
          window.location.href = `${config.vgw_platform.url}/authenticate?&token=${rmt}&rmt=1`
          return;
        }
      }

      this.state = this.user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'mfaChallenge' : 'mfaSetup'
    },

    handleAuthenticated() {
      Auth.currentAuthenticatedUser().then(user => {
        return this.redirectToApplication(user);
      })
    },

    async logout() {
      return await logout()
    },

    redirectToApplication(user) {
      window.location.href = `${config.vgw_platform.url}/authenticate?`
          + '&token=' + user.signInUserSession.idToken.getJwtToken()
          + '&source=' + (this.source ?? '')

      return true
    },

    redirectToAccountCreation() {
      window.location.href = `${config.vgw_platform.url}/register` + (this.source ? '?source=' + this.source : '')
    }
  }
}


</script>