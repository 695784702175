<template>
  <div>
    <template v-if="state === 'success'">
      <h1 class="pb-3">Password Changed</h1>
      <p class="h-info mb-12">Your new password is now active. To continue click the button below and then login with your new password.</p>
      <button type="button" @click="$emit('success')" class="primary mb-3">
        <div class="flex justify-center items-center">
            <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
            <span>Continue</span>
        </div>
      </button>
    </template>

    <template v-if="state === 'verify'">
      <h1 class="pb-3">Password Reset</h1>

      <p class="h-info pb-5">An email has been sent to you with a verification code. Please enter the code below and choose a new password.</p>

      <p v-if="errorMessage" class="error mb-4">{{ errorMessage }}</p>

      <label for="code" class="block text-sm font-normal text-gray-600 mb-2">Verification Code</label>
      <input @keyup.enter="verify" id="code" type="text" v-model="code" name="verification-code" autofocus class="block mb-4" autocomplete="off">

      <label for="new-password" class="block text-sm font-normal text-gray-600 mb-2">New Password</label>
      <input @keyup.enter="verify" id="new-password" type="password" name="new-password" v-model="password" class="block mb-12" autocomplete="off">

      <button type="button" @click="verify" class="primary mb-3">
        <div class="flex justify-center items-center">
          <spinner v-if="verifying"></spinner>
          <template v-if="!verifying">
            <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M14.5 2.5A5.5 5.5 0 0 1 20 8v4.5H9V8a5.5 5.5 0 0 1 5.5-5.5z"/><path d="M6 11.5h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2z"/></svg>
            <span>Verify</span>
          </template>
        </div>
      </button>

      <button @click="$emit('cancel')" type="button" class="secondary mb-12">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="840.000000pt" height="826.000000pt" viewBox="0 0 840.000000 826.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,826.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none"><path d="M2130 7504 c-1463 -668 -1565 -714 -1581 -714 -8 0 -39 -7 -67 -15 -110 -33 -214 -167 -259 -335 -17 -61 -18 -199 -18 -2325 0 -2126 1 -2264 18 -2325 45 -168 149 -302 259 -335 28 -8 59 -15 68 -15 10 0 53 -16 96 -37 43 -20 572 -261 1174 -535 1211 -551 1112 -514 1213 -460 94 51 175 168 214 312 17 62 18 243 18 3395 0 3152 -1 3333 -18 3395 -39 143 -119 261 -214 312 -102 55 -42 76 -903 -318z m272 -2775 c249 -52 406 -301 343 -544 -88 -336 -501 -447 -747 -202 -112 112 -156 286 -109 436 26 85 61 140 126 199 112 102 246 141 387 111z"/><path d="M3920 6910 l0 -300 810 0 810 0 0 -545 0 -545 261 0 260 0 -3 598 c-3 595 -4 597 -27 667 -68 203 -184 332 -357 397 l-59 23 -847 3 -848 3 0 -301z"/><path d="M6446 5973 c-3 -3 -6 -188 -6 -410 l0 -403 -1005 0 -1005 0 2 -932 3 -933 1003 -3 1002 -2 0 -368 c0 -203 3 -371 6 -375 5 -5 1688 1643 1732 1695 7 9 9 22 6 29 -4 8 -179 183 -389 389 -210 206 -598 588 -862 847 -264 260 -483 470 -487 466z"/><path d="M5547 3023 c-4 -3 -7 -321 -7 -705 l0 -698 -810 0 -810 0 0 -300 0 -300 814 0 c461 0 835 4 862 9 169 32 304 137 389 302 72 140 69 100 72 942 l4 757 -254 0 c-139 0 -257 -3 -260 -7z"/></g></svg>
          <span>Logout</span>
        </div>
      </button>

      <h2 class="pb-3">Haven't received your code?</h2>
      <p class="block h-info pb-5">You should have received an email containing a password reset verification code. If you didn't receive it, click the below button and we'll resend the email.</p>

      <p v-if="resendErrorMessage" class="error mb-4">{{ resendErrorMessage }}</p>
      <p v-if="resendSuccessMessage" class="success mb-4">{{ resendSuccessMessage }}</p>

      <button class="secondary mb-6" @click="resend">
        <div class="flex justify-center items-center">
          <svg class="w-4 h-4 mr-0.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" /><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" /></svg>
          <span>Resend verification code</span>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import {Auth} from 'aws-amplify';
import Spinner from "@/components/Spinner";

export default {
  name: 'PasswordReset',
  components: {Spinner},
  props: ['user', 'username'],
  data() {
    return {
      state: 'verify',
      code: '',
      password: '',
      verifying: false,
      errorMessage: '',
      resendErrorMessage: '',
      resendSuccessMessage: '',
    }
  },
  methods: {
    verify: async function () {
      this.verifying = true

      Auth.forgotPasswordSubmit(this.username, this.code, this.password)
          .then(() => {
            this.errorMessage = ''
            this.state = 'success'
          }).catch((err) => {
            console.log(err)
            this.errorMessage = err.message || 'The code is invalid'
          }).finally(() => {
            this.verifying = false
          })
    },

    resend() {
      this.resendSuccessMessage = ''
      Auth.forgotPassword(this.username)
          .then(() => {
            this.resendErrorMessage = ''
            this.resendSuccessMessage = 'Email sent successfully'
            setTimeout(() => {
              if (this.resendSuccessMessage) {
                this.resendSuccessMessage = ''
              }
            }, 10000)
          })
          .catch(err => {
            console.log(err)
            this.resendErrorMessage = err.message || 'Resending failed - please try again in a moment'
          });
    }
  }
}
</script>